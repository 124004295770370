import React, { useContext, useEffect, useCallback, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { StateContext } from "../../store/courses/contexts"
import { CoursesList } from "./CoursesList"
import NotFOundSVG from "../../images/not-found.svg"
import { SharedDivider } from "../shared"

const EWrapper = styled.div`
  display: none;

  &[data-display="true"] {
    display: block;
  }
`

export const Result = ({ firstNCourses, display = true }) => {
  const { state, dispatch } = useContext(StateContext)
  const { userText, foundCourses, originalCourses } = state
  const [list, setList] = useState([])

  const myCoursesList = useCallback((originalCourses, foundCourses) => {
    setList(
      originalCourses.filter((i) =>
        foundCourses.map((i) => i.Id).includes(i.Id)
      )
    )
  }, [])

  useEffect(() => {
    myCoursesList(originalCourses, foundCourses)
  }, [originalCourses, foundCourses])

  return (
    <>
      <EWrapper data-display={display && foundCourses.length > 0}>
        <CoursesList list={list} />

        <SharedDivider />
      </EWrapper>

      <EWrapper
        data-display={
          display &&
          foundCourses.length === 0 &&
          !!userText &&
          !(foundCourses.length > 0)
        }
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p {
              color: #525151;
              margin: 0;
              font-size: 16px;
              font-family: "Rubik", Arial, Helvetica, sans-serif;
              margin-top: 1em;
            }
          `}
        >
          <img
            src={NotFOundSVG}
            css={css`
              max-width: 300px;
              margin-top: 3em;
              margin-bottom: 4em;
            `}
          />

          <p>По вашому запиту нічого не було знайдено.</p>
          <p> Спробуйте ще раз :)</p>
        </div>

        <SharedDivider />
      </EWrapper>
      <EWrapper
        data-display={
          display &&
          !(foundCourses.length > 0) &&
          !(foundCourses.length === 0 && !!userText)
        }
      >
        <CoursesList list={originalCourses} omitHeaders={!!firstNCourses} />
        <SharedDivider />
      </EWrapper>
    </>
  )
}
