import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import * as JsSearch from "js-search"
import { useInput, useFocus } from "react-hookedup"
import { StateContext } from "../../store/courses/contexts"
import SearchSVG from "../../images/icons/bx-search.svg"

const Wrapper = styled.div`
  max-width: 106em;
  width: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 60px;
  margin-bottom: 3em;
  transition: all 0.2s;
  box-shadow: ${(props) =>
    props.active
      ? "0px 2px 13px rgba(0, 0, 0, 0.08)"
      : "0px 0px 0px rgba(0, 0, 0, 0.08)"};

  margin: 0 auto;
  border: 1px solid #e1e2e3;
  border-radius: 6px;
`

const ESearchInput = styled.input`
  border: 0;

  border-radius: 6px;
  width: 100%;
  height: 50px;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #525151;
  padding: 0 16px;

  ::placeholder {
    color: #525151;
  }
`

const ESearch = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fd7114;
  position: absolute;
  width: 72px;
  right: 0;
  border-radius: 10px;
  height: 100%;
  border: 4px solid #fff;

  img {
    height: 18px;
  }

  :hover {
    background-color: #ea640c;
  }

  :active {
    background-color: #ff6600;
  }
`

const b = new JsSearch.Search("Id")

b.addIndex("name")
b.addIndex("tags")
b.addIndex("description")
b.addIndex("location")

export const Search = () => {
  const searchInput = useInput("")
  const searchFocus = useFocus()
  const [myJsSearch] = useState(b)

  const { state, dispatch } = useContext(StateContext)
  const { originalCourses, asideFilter } = state

  useEffect(() => {
    const a = originalCourses.map((i) => i)
    myJsSearch.addDocuments(a)
  }, [])

  const search = () => {
    let found = []

    found = myJsSearch.search(searchInput.value)

    if (asideFilter && asideFilter.type === "relevant") {
      found = originalCourses.filter((i) => i.relevant)
    }

    if (asideFilter && asideFilter.type === "notRelevant") {
      found = originalCourses.filter((i) => !i.relevant)
    }

    if (asideFilter && asideFilter.type === "tag") {
      found = originalCourses.filter((i) => i.tags.includes(asideFilter.text))
    }

    if (asideFilter && asideFilter.type === "all") {
      found = originalCourses
    }

    dispatch({ type: "SET_FOUND", payload: found })
  }

  useEffect(() => {
    dispatch({ type: "SET_SEARCH_VALUE", payload: searchInput.value })

    if (!!searchInput.value) {
      dispatch({ type: "SET_ASIDE_FILTER", payload: null })
    }

    search()
  }, [
    searchInput.value,
    asideFilter && asideFilter.type,
    asideFilter && asideFilter.text,
  ])

  useEffect(() => {
    if (!!asideFilter) {
      searchInput.setValue("")
    }
  }, [asideFilter])

  return (
    <Wrapper active={searchFocus.focused}>
      <ESearchInput
        placeholder="Назва, тема курсу"
        {...searchInput.bindToInput}
        onFocus={() => {
          searchFocus.bind.onFocus()
        }}
        onBlur={() => {
          searchFocus.bind.onBlur()
        }}
      />

      <div
        css={css`
          position: relative;
        `}
      >
        <ESearch>
          <img src={SearchSVG} />
        </ESearch>
      </div>
    </Wrapper>
  )
}
