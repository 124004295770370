import React, { useReducer, useMemo } from "react"
import { css } from "@emotion/react"
import { StaticQuery, graphql } from "gatsby"
import { LeftSideNavigation } from "../components/courses/LeftSideNavigation"
import { Result } from "../components/courses/Result"
import { Search } from "../components/courses/Search"
import appReducer from "../store/courses/reducers"
import { StateContext } from "../store/courses/contexts"
import styled from "@emotion/styled"

const EWrapper = styled.div`
  display: none;
  grid-template-columns: 23em 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }

  @media (min-height: 641px) {
    min-height: 90vh;
  }
  &[data-display="true"] {
    display: grid;
  }
`

const Component = ({ data, firstNCourses }) => {
  const originalCourses = useMemo(
    () =>
      firstNCourses
        ? data.allCourses.edges.map((i) => i.node).slice(0, firstNCourses)
        : data.allCourses.edges.map((i) => i.node),
    [firstNCourses]
  )

  const initialState = {
    originalCourses: originalCourses,
    userText: "",
    foundCourses: [],
    asideFilter: null,
  }

  const [state, dispatch] = useReducer(appReducer, initialState)
  return (
    <>
      <StateContext.Provider value={{ state, dispatch }}>
        <EWrapper data-display={!firstNCourses}>
          <LeftSideNavigation />

          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <Search />
            <Result />
          </div>
        </EWrapper>

        <Result display={!!firstNCourses} firstNCourses={firstNCourses} />
      </StateContext.Provider>
    </>
  )
}

const Courses1 = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allCourses {
          edges {
            node {
              courseStatus
              startApplyDate
              endCourseDate
              Id
              buttonCaption
              courseImageName
              date
              dateUndefined
              description
              discount
              id
              link
              name
              shortName
              goLearnYourselfLink
              location
              online
              orangeButton
              pricePerCourse
              pricePerMonth
              priceString
              relevant
              tags
              learnOnYOurselfPrice
              buyOnUdemyCaption
              buyOnUdemyCaption2
              freeAccessLink
              students
              lections {
                link
                header
                presentationLink
                description
              }
              startCourseDate
              startCourseDateReference
              duration
              team {
                header
                pic
                name
                position
                linkedin
                description
              }
              schedule {
                header
                weeksText
                lessons
                weeks {
                  header
                  lessons {
                    header
                    list
                  }
                }
              }
              questions {
                header
                text
              }

              featuresList {
                text
                color
                icon
              }
              videos
              pracricalLessons
              registerLink
              duration
              secondInfoCardCourse
            }
          }
        }
      }
    `}
    render={(data) => {
      return <Component {...props} data={data} />
    }}
  />
)

export default Courses1
