import React, { useContext, useEffect } from "react"
import styled from "@emotion/styled"
import { StateContext } from "../../store/courses/contexts"
import FireSVG from "../../images/icons/fire.svg"
import img2 from "../../images/icons/bxs-widget.svg"
import img3 from "../../images/icons/bxs-time-five.svg"
import _flatten from "lodash/flatten"

const EAside = styled.aside`
  display: flex;
  flex-direction: column;
`

const Element = styled.div`
  background: ${(props) => (props.active ? "#FFF7EE" : "transparent")};

  :hover {
    background: #fff2e4;
  }
  :active {
    background: #fff7ee;
  }

  display: grid;
  grid-template-columns: 14px 1fr 20px;
  grid-column-gap: 14px;
  border-radius: 48px;
  min-height: 48px;

  padding: 0 2.5em;
  border: 0;
  cursor: pointer;
  align-content: center;

  @media (max-width: 640px) {
    min-height: 3.2em;
  }

  h2 {
    font-size: 1.43em;

    color: ${(props) => (props.active ? "#111" : "#525151")};
    margin: 0;
    text-align: initial;
    font-weight: 500;
  }

  p {
    font-size: 1.43em;

    color: ${(props) => (props.active ? "#111" : "#525151")};
    margin: 0;
    text-align: end;
    font-weight: 500;
  }
`

export const LeftSideNavigation = () => {
  const { state, dispatch } = useContext(StateContext)
  const { asideFilter } = state
  const tags = _flatten(state.originalCourses.map((i) => i.tags))

  const uniqueTags = [...new Set(tags)]

  const handleFilterChange = (obj) => {
    dispatch({ type: "SET_ASIDE_FILTER", payload: obj })
    dispatch({ type: "SET_SEARCH_VALUE", payload: "" })
  }

  return (
    <EAside>
      <Element
        active={asideFilter && asideFilter.type === "relevant"}
        onClick={() => {
          handleFilterChange({ type: "relevant" })
        }}
      >
        <div>
          <img src={FireSVG} />
        </div>
        <h2>Актуальні</h2>
        <p>{state.originalCourses.filter((i) => i.relevant).length}</p>
      </Element>

      <Element
        active={asideFilter && asideFilter.type === "notRelevant"}
        onClick={() => {
          handleFilterChange({ type: "notRelevant" })
        }}
      >
        <div>
          <img src={img3} />
        </div>

        <h2>Відбулись</h2>
        <p>{state.originalCourses.filter((i) => !i.relevant).length}</p>
      </Element>

      <Element
        active={asideFilter && asideFilter.type === "all"}
        onClick={() => {
          handleFilterChange({ type: "all" })
        }}
      >
        <div>
          <img src={img2} />
        </div>
        <h2>Всі теми</h2>
        <p>{state.originalCourses.length}</p>
      </Element>

      {uniqueTags.map((i, index) => (
        <Element
          key={`${i}aside${index}`}
          active={
            asideFilter && asideFilter.type === "tag" && asideFilter.text === i
          }
          onClick={() => {
            handleFilterChange({ type: "tag", text: i })
          }}
        >
          <div />
          <h2>{i}</h2>
          <p>{tags.filter((j) => j === i).length}</p>
        </Element>
      ))}
    </EAside>
  )
}
