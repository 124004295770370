import React, { useContext, useEffect, useMemo } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { VacancyCard } from "./VacancyCard"
import FireSVG from "../../images/icons/fire.svg"
import ClockSVG from "../../images/icons/bxs-time-five-black.svg"

const EH3 = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 10px;
  margin: 4em 0 1em 0;

  h3 {
    margin: 0;
    font-size: 24px;
  }

  p {
    color: #8f96a1;
    font-size: 1.2em;
    @media (max-width: 640px) {
      font-size: 14px;
    }
    line-height: 1.5;
    max-width: 574px;
  }
`

const EList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 865px) {
    grid-template-columns: 1fr;
  }
`

export const CoursesList = ({ list, omitHeaders }) => {
  const relevantCoursesList = useMemo(() => list.filter((i) => i.relevant), [
    JSON.stringify(list),
  ])

  const irrelevantCoursesList = useMemo(() => list.filter((i) => !i.relevant), [
    JSON.stringify(list),
  ])

  if (omitHeaders) {
    return (
      <EList>
        {list.map((i, index) => {
          return <VacancyCard {...i} key={`${index}${i.link}`} />
        })}
      </EList>
    )
  }
  return (
    <>
      {relevantCoursesList.length > 0 && (
        <>
          <EH3>
            <div>
              <img src={FireSVG} />
            </div>
            <h3>Актуальні</h3>
          </EH3>

          <EList>
            {relevantCoursesList.map((i, index) => {
              return <VacancyCard {...i} key={`${index}${i.link}`} />
            })}
          </EList>
        </>
      )}

      {irrelevantCoursesList.length > 0 && (
        <>
          <EH3
            css={css`
              margin: 50px 0 30px 0;
            `}
          >
            <div>
              <img
                src={ClockSVG}
                css={css`
                  margin-top: 3px;
                `}
              />
            </div>
            <div>
              <h3>Відбулись</h3>
              <p>
                Протягом 2015-2020 років ми провели вісім навчальних інтенсивів,
                їх матеріали ви можете переглянути у вільному доступі
              </p>
            </div>
          </EH3>
          <EList>
            {irrelevantCoursesList.map((i, index) => (
              <VacancyCard {...i} key={`${index}${i.link}`} />
            ))}
          </EList>
        </>
      )}
    </>
  )
}
