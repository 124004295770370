const userTextReducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH_VALUE":
      return action.payload

    default:
      return state
  }
}

const foundCoursesReducer = (state, action) => {
  switch (action.type) {
    case "SET_FOUND":
      return action.payload

    default:
      return state
  }
}

const originalCoursesReducer = (state, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const asideFilterReducer = (state, action) => {
  switch (action.type) {
    case "SET_ASIDE_FILTER":
      return action.payload

    default:
      return state
  }
}

export default function appReducer(state, action) {
  return {
    userText: userTextReducer(state.userText, action),
    foundCourses: foundCoursesReducer(state.foundCourses, action),
    originalCourses: originalCoursesReducer(state.originalCourses, action),
    asideFilter: asideFilterReducer(state.asideFilter, action),
  }
}
